import React from 'react';
import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';

import eCommerce from '../assets/images/e-commerce.png';
import platziBadges from '../assets/images/react.png';
import bgMaster from '../assets/video/background_optimized.mp4';
import marvelImg from '../assets/images/marvel.png';
const IndexPage = () => (
  <Layout>
    <Header />

    <header className="masthead">
      <video
        autoPlay
        muted
        loop
        className="masthead__videoBg img-fluid mb-3 mb-lg-0"
        alt=""
      >
        <source src={bgMaster} type="video/mp4" />
      </video>
      <div className="masthead__content container d-flex h-100 align-items-center">
        <div className="mx-auto text-center">
          <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
          <h2 className="text-white-50 mx-auto mt-2 mb-5">
            {config.subHeading}
          </h2>
          <Scroll type="id" element="about">
            <a href="#about" className="btn btn-primary">
              About
            </a>
          </Scroll>
        </div>
      </div>
    </header>

    <section id="about" className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2 className="text-white mb-4">About me</h2>
            <p className="text-white-50">
              This is my portfolio website. Here you can find the projects I
              have done. I like to make projects that challenge me to learn and
              master new things.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section id="projects" className="projects-section bg-light">
      <div className="container">
        <div className="row align-items-center no-gutters mb-4 mb-lg-5">
          <div className="col-xl-8 col-lg-7">
            <div className="img-fluid mb-3 mb-lg-0" alt="">
              <a
                href="https://guillermoloza.github.io/marvelProject/#/"
                className="nav-link"
                target="_blank"
              >
                <img
                  src={marvelImg}
                  className="img-fluid shadow-sm"
                  alt="Marvel project page screenshot"
                />
              </a>
            </div>
          </div>
          <div className="col-xl-4 col-lg-5">
            <div className="featured-text text-center text-lg-left">
              <h4>Marvel Search</h4>
              <p className="text-black-50 mb-0">
                Is a searcher that makes use of the marvel API to fetch and
                print data depending on user input.
              </p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters mb-5 mb-lg-0">
          <div className="col-lg-6">
            <img
              className="img-fluid  shadow-sm"
              src={eCommerce}
              alt="e-commerce page screenshot"
            />
          </div>
          <div className="col-lg-6">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-left">
                  <h4 className="text-white">E-commerce</h4>
                  <p className="mb-0 text-white-50">
                    A highly optimized e-commerce site created with gatsby js.
                    The project has different functions, such as: add to cart,
                    manage products for sale, select clothing sizes, calculate
                    total and subtotal price.An example of where you can put an
                    image of a project, or anything else, along with a
                    description.
                  </p>
                  <hr className="d-none d-lg-block mb-0 ml-0" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center no-gutters">
          <div className="col-lg-6">
            <img className="img-fluid  shadow-sm" src={platziBadges} alt="" />
          </div>
          <div className="col-lg-6 order-lg-first">
            <div className="bg-black text-center h-100 project">
              <div className="d-flex h-100">
                <div className="project-text w-100 my-auto text-center text-lg-right">
                  <h4 className="text-white">Platzi Badges</h4>
                  <p className="mb-0 text-white-50">
                    Another example of a project with its respective
                    description. These sections work well responsively as well,
                    try this theme on a small screen!
                  </p>
                  <hr className="d-none d-lg-block mb-0 mr-0" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
